import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

export const useRetrieveData = (type) => {

    const dispatch = useDispatch()
    const [data, setData] = useState(null)

    useEffect(() => {

        const rowsData = [
            {
                id:1,
                title: "Trending"
            },
            {
                id:2,
                title: "Popular"
            },
            {
                id:3,
                title: "Viral Videos"
            },
            {
                id:4,
                title: "Famous in your region"
            },
            {
                id:5,
                title: "Upcoming"
            }
        ]
        setData(rowsData)

    }, [type, dispatch])

    return data
}
