import "./banner.scss";
import React from "react";
import { motion } from "framer-motion";
import { staggerOne, bannerFadeInVariants, bannerFadeInUpVariants } from "../../motionUtils";
import { FaPlay } from "react-icons/fa";
import { BiInfoCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showModalDetail } from "../../redux/modal/modal.actions";
import { BASE_URL, COVER_PHOTO, BANNER } from "../../consts"
const Banner = () => {
	const dispatch = useDispatch();

	const handlePlayAnimation = event => {
		event.stopPropagation();
	};

	const handleModalOpening = () => {
		const finalData = {
			id: 0,
			fallbackTitle: "Shahab Namay: Travel Diary of Life",
			genre_ids: [28, 12],
			isFavourite: false,
			backdrop_path: BANNER,
			description: "Find out the beginning, and how the realization from past lead to the concept of this travel diary of life"
		}

		dispatch(showModalDetail(finalData));
	}

	return (
		<>
			{(
				<motion.header
					variants={bannerFadeInVariants}
					initial='initial'
					animate='animate'
					exit='exit'
					className="Banner"
					style={{ backgroundImage: `url(${BASE_URL + COVER_PHOTO})` }}
				>
					<motion.div
						className="Banner__content"
						variants={staggerOne}
						initial='initial'
						animate='animate'
						exit='exit'
					>
						<motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--title">Shahab Namay: Travel Diary of Life</motion.h1>
						<motion.div variants={bannerFadeInUpVariants} className="Banner__buttons">
							<Link
								className="Banner__button"
								onClick={handlePlayAnimation}
								to={`/play?id=0`}

							>
								<FaPlay />
								<span>Play</span>
							</Link>
							<button
								className="Banner__button"
								onClick={handleModalOpening}
							>
								<BiInfoCircle size="1.5em" />
								<span>More info</span>
							</button>
						</motion.div>
						<motion.p variants={bannerFadeInUpVariants} className="Banner__content--description">Find out the beginning, and how the realizations from past lead to the concept of this travel diary of life</motion.p>
					</motion.div>
					<div className="Banner__panel" />
					<div className="Banner__bottom-shadow" />
				</motion.header>
			)}
		</>
	)
}

export default React.memo(Banner);