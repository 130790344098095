import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Navbar from "./components/Navbar/Navbar"
import Homepage from "./pages/Homepage/Homepage"
import Movies from "./pages/Movies/Movies"
import TVSeries from './pages/TVSeries/TVSeries';
import Popular from "./pages/Popular/Popular";
import Category from "./pages/Category/Category";
import DetailModal from "./components/DetailModal/DetailModal";
import PlayAnimation from "./components/PlayAnimation/PlayAnimation";

const App = () => {

    const location = useLocation();

    return (
        <div className="App">
            {
                <>
                    <Navbar />
                    <DetailModal />
                </>
            }
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    <Route
                        exact
                        path="/"
                    >
                        <Redirect to="/browse" />
                    </Route>
                    <Route
                        path="/play"
                        component={PlayAnimation}
                    />
                    <Route
                        exact
                        path="/browse"
                        render={() => <Homepage />
                        }
                    />
                    <Route
                        exact
                        path="/browse/:categoryName"
                        render={(props) => <Category {...props} />
                        }
                    />
                    <Route
                        exact
                        path="/travel-diaries"
                        render={() => <TVSeries />}
                    />
                    <Route
                        exact
                        path="/travel-diaries/:categoryName"
                        render={(props) => <Category {...props} />
                        }
                    />
                    <Route
                        exact
                        path="/parenting-discussion"
                        render={() => <Movies />}
                    />
                    <Route
                        exact
                        path="/parenting-discussion/:categoryName"
                        render={(props) => <Category {...props} />
                        }
                    />
                    <Route
                        exact
                        path="/social-issues"
                        render={() => <Popular />}
                    />
                    <Route
                        exact
                        path="/social-issues/:categoryName"
                        render={(props) => <Category {...props} />
                        }
                    />
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </AnimatePresence>
        </div>
    )
}

export default App;
