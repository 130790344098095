import "./playAnimation.scss"
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TADUM_SOUND_URL } from "../../requests";
import { MediaController, MediaControlBar, MediaPlayButton, MediaMuteButton, MediaVolumeRange, MediaTimeRange, MediaPipButton, MediaFullscreenButton } from "media-chrome/dist/react"
import 'hls-video-element';
import { defaultPageFadeInVariants } from "../../motionUtils";
import { motion } from "framer-motion";
import { staggerOne, bannerFadeInVariants } from "../../motionUtils";
import { BASE_URL } from "../../consts"

const PlayAnimation = () => {

	const location = useLocation();

	// Function to parse query strings
	const getQueryStringValue = (key) => {
		return new URLSearchParams(location.search).get(key);
	};

	let history = useHistory();
	const soundRef = useRef(null);
	const handleTadum = () => {
		soundRef.current.currentTime = 0;
		soundRef.current.play();
	}

	const [playVideo, setVideo] = useState(false)
	const [videoId, setVideoId] = useState(0)
	const [videoTitle, setVideoTitle] = useState("")

	useEffect(() => {
		handleTadum();
		setTimeout(() => {
			const id = getQueryStringValue('id');
			const mapping = {
				0: "b38d0358-8f4a-45ae-8e78-04c4cbe32eb5/AppleHLS1/ShahabNama.m3u8",
				1: "4dfb6334-08a6-4256-abb9-bf538316ce19/AppleHLS1/bakraScene.m3u8",
				2: "14009f2e-9cc0-48b7-9577-67ce477b91e6/AppleHLS1/DSTlandscape.m3u8",
				3: "3859c49b-b31b-41aa-bdc8-76459eb53fcf/AppleHLS1/gardenGroveMasjidEvent.m3u8",
				4: "eb25fd9f-673f-43a5-92d9-3093fa9baccc/AppleHLS1/GeneralSherman.m3u8",
				5: "39c35044-dc89-43c9-8093-197b66f5ccf3/AppleHLS1/lemonade landscape.m3u8",
				6: "55221c57-950f-4fda-94ff-9a4d67e61410/AppleHLS1/ninas.m3u8",
				7: "69bbee90-9c75-4493-9697-e015d9f238d6/AppleHLS1/shab e juma.m3u8",
				8: "98488535-cd7e-4202-b4af-d6052b554768/AppleHLS1/puzzles.m3u8"
			}
			const titleMapping = {
				0: "Shahab Namay: Beginning",
				1: "California kay Bakray",
				2: "Daylight Saving Time",
				3: "Ramazan Events",
				4: "World Biggest Tree",
				5: "Lemonade Stand in America",
				6: "America main desi Grocery",
				7: "Shab e Jumma in America",
				8: "Importance of Solving Puzzles"
			}
			setVideoId(mapping[id])
			setVideoTitle(titleMapping[id])
			setVideo(true)
		}, 4200)
	}, [history])

	return (
		<>
			{!playVideo && <div className='PlayAnimation__wrp'>
				{
					<>
						<audio ref={soundRef} src={TADUM_SOUND_URL} />
						<span className="PlayAnimation__text">
							Shahab Namay
						</span>
					</>
				}
			</div >
			}
			{
				playVideo && <>
					<motion.div
						className="Homepage"
						variants={defaultPageFadeInVariants}
						initial="initial"
						animate="animate"
						exit="exit"
						style={{
							width: '100vw',
							height: '100vh',
						}}
					>
						<motion.header
							variants={bannerFadeInVariants}
							initial='initial'
							animate='animate'
							exit='exit'
							className="Banner"
						>
							<motion.div
								className="Banner__content"
								variants={staggerOne}
								initial='initial'
								animate='animate'
								exit='exit'
							>
								<MediaController style={{
									position: 'fixed', // or 'absolute'
									top: 0,
									left: 0,
									width: '100vw',
									height: '100vh',
									display: 'flex',
									justifyContent: 'center', // Center content horizontally
									alignItems: 'center', // Center content vertically
									overflow: 'auto', // Adds scrollbars if content overflows
								}}>
									<hls-video
										src={BASE_URL + videoId}
										slot="media"
										crossorigin
										muted>
									</hls-video>
									<media-loading-indicator slot="centered-chrome" noautohide></media-loading-indicator>
									<MediaControlBar>
										<MediaPlayButton></MediaPlayButton>
										<MediaMuteButton></MediaMuteButton>
										<MediaVolumeRange></MediaVolumeRange>
										<MediaTimeRange></MediaTimeRange>
										<MediaPipButton></MediaPipButton>
										<MediaFullscreenButton></MediaFullscreenButton>
									</MediaControlBar>
								</MediaController>
								<div style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'flex-start',
									height: '5vh',
									width: '100vw',
									position: 'absolute',
									top: 15,
									left: 0,
									color: "black"
								}}>
									<h2>{videoTitle}</h2>
								</div>
							</motion.div>
							<div className="Banner__panel" />
							<div className="Banner__bottom-shadow" />
						</motion.header>
					</motion.div>
				</>
			}
		</>
	)
}

export default PlayAnimation
