import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { fetchMovieDataConfig, fetchPopularDataConfig, fetchSeriesDataConfig } from "../dataConfig";

export const useRetrieveCategory = (slicedUrl, categoryName, page) => {

	const dispatch = useDispatch();
	const [categoryData, setCategoryData] = useState();

	useEffect(() => {
		let selectedConfigArray = null;
		switch (slicedUrl) {
			case "browse":
			case "parenting-discussion":
				selectedConfigArray = fetchMovieDataConfig;
				break;
			case "travel-diaries":
				selectedConfigArray = fetchSeriesDataConfig;
				break;
			case "social-issues":
				selectedConfigArray = fetchPopularDataConfig;
				break;
			default:
				break;
		}

		const [data] = selectedConfigArray.filter(el => el.genre === categoryName);
		dispatch(data.thunk(`${data.url}&page=${page}`));
		setCategoryData(data);

	}, [dispatch, categoryName, slicedUrl, page])

	return categoryData;
}