import "./credits.scss"
import { motion } from "framer-motion";
import { creditsFadeInUpVariants } from "../../motionUtils";

const Credits = () => {
	return (
		<motion.footer
			variants={creditsFadeInUpVariants}
			initial='initial'
			animate='animate'
			exit='exit'
			className='Credits'
		>
			<span>Developed by</span>
			<motion.a whileTap={{scale: 0.9}} className='Credits__linkwrp' href="https://www.linkedin.com/in/affanshahab/" target='_blank' rel='noreferrer'>
				<span> Affan Shahab</span>
			</motion.a>
			<span style={{marginLeft:"10px", marginRight: "10px"}}>|</span>
			<span>Find us on YouTube</span>
			<motion.a whileTap={{scale: 0.9}} className='Credits__linkwrp' href="https://www.youtube.com/@ShahabNamay" target='_blank' rel='noreferrer'>
				<span>Shahab Namay</span>
			</motion.a>

		</motion.footer>
	)
}

export default Credits;
