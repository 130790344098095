import "./row.scss";
import RowPoster from "../RowPoster/RowPoster";
import { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import useViewport from "../../hooks/useViewport";
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { FiChevronRight } from "react-icons/fi";

// Swiper
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Pagination]);

const Row = ({ title, genre, isLarge }) => {
	const { width } = useViewport();

	const { pathname } = useLocation();

	const _results = [
		{
			id: 1,
			title: "California kay Bakray",
			genre_ids: [28, 12],
			isFavourite: false,
			backdrop_path: "website-artifacts/1.jpg",
			description: "Is episode main dekhen California ki Bakra perhi or janiye america kay halal gosht ki market se mutaliq kuch malomat"
		},
		{
			id: 2,
			title: "Daylight Saving Time",
			genre_ids: [28, 12],
			isFavourite: false,
			backdrop_path: "website-artifacts/2.jpg",
			description: "Kya aap jante hain ke Daylight Saving Time (DST) kya hota hai aur iska asal maqsad kya hai? Is video me, hum DST ke dilchasp haqaiq, iske fawaid, aur is se hone wale nuqsanat ki gehrai se baat karenge. Hum DST ki history aur iske peechay ki soch ko samjhen ge, iske faide kya hain, jaise ke bijli ki bachat aur zyada din ki roshni ka faida uthana, aur iske sath hi sath kuch challenges aur nuqsanat par bhi ghor karenge. Agar aap DST ke asraat aur is se judi mukhtalif perspectives ko samajhna chahte hain, to yeh video zaroor dekhen. Hamara maqsad hai ke aapko DST ki mukammal tasveer pesh karein, taake aap is pe aik mukammal raaye qaim kar saken"
		},
		{
			id: 3,
			title: "Ramazan Events",
			genre_ids: [28, 12],
			isFavourite: false,
			backdrop_path: "website-artifacts/3.jpg",
			description: "Hum lekar aaye hain aapke liye ek khaas video, jismein humne dikhaya hai ke kaise California ke ek masjid mein Ramadan ka paak mahina manaya jaata hai. Iss video mein, aap dekhenge ki kis tarah se hamari masjid aur hamara community Ramadan ke is paak mahina ko bade hi utsah aur pyaar se manate hain. Yeh video na sirf aapko California ke masjid mein manaye jaane wale Ramadan ke tyohaar ki ek jhalak dega, balki yeh aapko dikhaayega ki kaise hamari masjid aur hamara community mil kar is paak mahine ko aur bhi khaas banate hain. Aaeye, is paak mahine ko humare saath celebrate karein aur dekhein ki kaise humne California mein Ramadan ka tyohaar manaya."
		},
		{
			id: 4,
			title: "World Biggest Tree",
			genre_ids: [28, 12],
			isFavourite: false,
			backdrop_path: "website-artifacts/4.png",
			description: "This video doesn't do justice at all in showing the real perspective. Discover the Majestic General Sherman Tree in this episode of شہاب نامے - Shahab Namay: The World's Largest Living Tree by Volume in California's Sequoia National Park. Height at 275 feet (83 meters) tall, to put this in perspective, the length of a Boeing 747-8 airplane is about 76 meters (250 feet). This tree is taller than 747-8 if plane was standing. It's circumference is 102 feet (31 meters) at its base, General Sherman is not just a tree, but a living monument, estimated to be about 2,200 years old. It is estimated to be even existing when Prophet Essa (AS) was born. With a volume of 52,508 cubic feet (1,487 cubic meters), if it is filled up, it can hold water equivalent to 38.6 standard shipping containers. Pause at 0:31 to read another perspective on information board. It's a natural wonder that reminds us of the ancient world's beauty and resilience. Join me in exploring this giant tree, a symbol of  strength and the awe-inspiring power of nature."
		},
		{
			id: 5,
			title: "Lemonade Stand in America",
			genre_ids: [28, 12],
			isFavourite: false,
			backdrop_path: "website-artifacts/5.jpg",
			description: "Since I became a father, my perspective of looking at things have been constantly evolving, I think journey so far have thought me more than what I am able to teach my kids. In this episode of شہاب نامے - Shahab Namay see my two cents on a this very common American practice of kids and how it relates to our country Pakistan."
		},
		{
			id: 6,
			title: "America main desi Grocery",
			genre_ids: [28, 12],
			isFavourite: false,
			backdrop_path: "website-artifacts/6.jpg",
			description: "Aaj ke is video mein, hum America mein Desi grocery stores ki sair karenge. Yeh stores hamare Desi khanaon, masalon, aur roz marra ki cheezon se bharpoor hain jo humein apne ghar se door, phir bhi ghar jaisa mehsoos karate hain. Hum aapko dikhayenge ke kis tarah yeh stores hamari Desi community ko jodte hain aur America mein hamare cultural heritage ko zinda rakhte hain. Hum california kay is ilaqe mein maujood is mashhoor Desi grocery stores ka daura karenge, iski khasiyat, aur yahan dastiyaab products ke bare mein baat karenge. Toh, agar aap Desi khana aur products ko yaad karte hain ya phir apne cultural roots se judna chahte hain, toh yeh video zaroor dekhein. Humein follow karein aur is safar ka hissa banein. Shukriya!"
		},
		{
			id: 7,
			title: "Shab e Jumma in America",
			genre_ids: [28, 12],
			isFavourite: false,
			backdrop_path: "website-artifacts/7.jpg",
			description: "Aaj ki is khaas video mein, hum aap ko apne ek yaadgaar safar ka tajurba share karenge, jahan hum ne California ke ek khubsurat masjid mein Shab e Jumma ki taqreebat ka hissa banaya. Dekhiye hamari video ko end tak aur humare sath apne thoughts aur feelings share kijiye comments mein. Agar aap ko yeh video pasand aaye toh please like karein, share karein, aur hamare channel ko subscribe zarur karein taake aap hamari aeenda videos se bhi roshan ho sakein."
		},
		{
			id: 8,
			title: "Importance of Solving Puzzles",
			genre_ids: [28, 12],
			isFavourite: false,
			backdrop_path: "website-artifacts/8.jpg",
			description: "Is video mein hum bachon ke zehni aur fikri development par roshni daalte hain, khas tor par mantaqi soch (logical thinking) aur namoona shinaakht (pattern recognition) ke zariye. Hamare expert bata rahe hain ke kis tarah puzzles aur dimaaghi mashqain bachon ki sochne samajhne ki salahiyat ko behtar bana sakti hain, unki riyazi maharat ko enhance kar sakti hain, aur programming ki dunya se unka taaruf karwa sakti hain.Hum dekhenge ke: Logical thinking kya hoti hai aur ye bachon ke learning process mein kis tarah madadgar sabit hoti hai. Pattern recognition ki maharat kaise develop ki ja sakti hai aur ye bachon ke academic aur professional zindagi mein kis tarah faida mand hai.	programming ka taluq kis tarah se bachon ki sochne ki salahiyat ko behtar bana sakta hai. Is video ko dekh kar, walidain aur asatza ko ummeed hai ke bachon ki taleem aur tarbiyat mein jadeed aur mufeed tareeqon ka istemal karne ka moqa milega. Aaiye, hum mil kar bachon ko unki poori potential tak pohchane mein madad karein."
		}
	]

	const __result = _results
		.map(value => ({ value, sort: Math.random() }))
		.sort((a, b) => a.sort - b.sort)
		.map(({ value }) => value)

	//Custom Swiper config
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const customSwiperParams = {
		observer: true,
		observeParents: true,
		navigation: {
			prevEl: navigationPrevRef.current,
			nextEl: navigationNextRef.current,
		},
		breakpoints: {
			1378: { slidesPerView: 6, slidesPerGroup: 6 },
			998: { slidesPerView: 4, slidesPerGroup: 4 },
			625: { slidesPerView: 3, slidesPerGroup: 3 },
			330: { slidesPerView: 2, slidesPerGroup: 2 },
			0: { slidesPerView: 1.5, slidesPerGroup: 1.5 }
		},
		loopAdditionalSlides: width >= 1378 ? 5 : width >= 998 ? 3 : width >= 625 ? 2 : 2,
		pagination: true,
		loop: false,
		grabCursor: false,
		draggable: false,
		preventClicksPropagation: true,
		preventClicks: true,
		slideToClickedSlide: false,
		allowTouchMove: true
	};

	const rightMouseOver = (e) => {
		if (e.currentTarget.classList.contains('right')) { e.currentTarget.parentElement.classList.add('is-right') }
		else if (e.currentTarget.classList.contains('left')) { e.currentTarget.parentElement.classList.add('is-left') }
	}

	const rightMouseOut = (e) => {
		e.currentTarget.parentElement.classList.remove('is-right', 'is-left')
	}

	const insertPositionClassName = (index) => {
		const i = index + 1

		if (i === 1) return 'left'
		else if (i === 20) return 'right'

		if (width >= 1378) {
			if ([7, 13, 19].includes(i)) return 'left'
			else if ([6, 12, 18].includes(i)) return 'right'
		} else if (width >= 998) {
			if ([5, 9, 13, 17].includes(i)) return 'left'
			else if ([4, 8, 12, 16].includes(i)) return 'right'
		} else if (width >= 768) {
			if ([4, 7, 10, 13, 16].includes(i)) return 'left'
			else if ([3, 6, 9, 12, 15, 18].includes(i)) return 'right'
		}
	}

	return (
		<div className="Row">
			{(
				<h3 className="Row__title">
					<Link to={`${pathname}/${genre}`}>
						<span>{title}</span>
						<span className='Row__showmore'>Show all <FiChevronRight /></span>
					</Link>
				</h3>
			)
			}
			{(
				<div className="Row__poster--wrp">
					<div className="Row__slider--mask left" ref={navigationPrevRef}>
						<MdChevronLeft className="Row__slider--mask-icon left" size="3em" style={{ color: 'white' }} />
					</div>
					<div className="Row__slider--mask right" ref={navigationNextRef}>
						<MdChevronRight className="Row__slider--mask-icon right" size="3em" style={{ color: 'white' }} />
					</div>
					<Swiper
						{...customSwiperParams}
						onBeforeInit={(swiper) => {
							swiper.params.navigation.prevEl = navigationPrevRef.current;
							swiper.params.navigation.nextEl = navigationNextRef.current;
						}}
					>
						{
							__result &&
							__result.map((result, i) => (
								<SwiperSlide
									key={result.id}
									className={insertPositionClassName(i)}
									onMouseOver={rightMouseOver}
									onMouseOut={rightMouseOut}
								>
									<RowPoster
										item={result}
										isLarge={isLarge}
										isFavourite={result.isFavourite}
										key={result.id}
									/>
								</SwiperSlide>
							))
						}
					</Swiper>
				</div>
			)}
		</div>
	);
};

export default Row;
